<template lang="pug">
  div
    v-container.py-12
      v-row.justify-center
        div.col-xs-12.col-sm-7.col-md-7.col-lg-7
          div.cssanimation.swingInLeft
            img(:src="Tablet" width="650px").tablet

        div.col-xs-12.col-sm-5.col-md-5.col-lg-5
          div.cssanimation.leFlyInRight.sequence.mt-10
            span.d-flex.flex-column.align-end
              div.mb-6.font-30.bold-600.text-right {{ $t('whyMyCourse.text1') }}

              div.d-flex.align-center
                div.subtitle.pa-0.text-right
                  div.mr-2 {{ $t('whyMyCourse.text2.part1') }}
                  div {{ $t('whyMyCourse.text2.part2') }}
                div.font-50.bold-800.pa-0.ml-4 1

              div.d-flex.align-center
                div.subtitle.pa-0.text-right
                  div.mr-2 {{ $t('whyMyCourse.text3.part1') }}
                  div {{ $t('whyMyCourse.text3.part2') }}
                div.font-50.bold-800.pa-0.ml-4 2

              div.d-flex.align-center
                div.subtitle.pa-0.text-right
                  div.mr-2 {{ $t('whyMyCourse.text4.part1') }}
                  div {{ $t('whyMyCourse.text4.part2') }}
                div.font-50.bold-800.pa-0.ml-4 3

              div.d-flex.align-center
                div.subtitle.pa-0.text-right
                  div.mr-2 {{ $t('whyMyCourse.text5.part1') }}
                  div {{ $t('whyMyCourse.text5.part2') }}
                div.font-50.bold-800.pa-0.ml-4 4



      v-row.justify-center.align-center
        div.font-40.bold-600.mb-10.text-center {{ $t('whyMyCourse.text6') }}

        v-row(align="center" justify="center").mb-6
          div.col-xs-12.col-sm-4.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
            span
              div
                img(:src="Boy2" width="115px")
              div.title.mb-4
                div {{ $t('whyMyCourse.text7.part1') }}
                div {{ $t('whyMyCourse.text7.part2') }}
              div.mb-4 {{ $t('whyMyCourse.text8') }}
              div {{ $t('whyMyCourse.text9') }}

          div.col-xs-12.col-sm-4.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
            span
              div
                img(:src="Boy3" width="100px")
              div.title.mb-4
                div {{ $t('whyMyCourse.text10.part1') }}
                div {{ $t('whyMyCourse.text10.part2') }}
              div.mb-4 {{ $t('whyMyCourse.text11') }}
              div {{ $t('whyMyCourse.text12') }}

          div.col-xs-12.col-sm-4.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
            span
              div
                img(:src="Girl3" width="115px")
              div.title.mb-4
                div {{ $t('whyMyCourse.text13.part1') }}
                div {{ $t('whyMyCourse.text13.part2') }}
              div.mb-4 {{ $t('whyMyCourse.text14') }}
              div {{ $t('whyMyCourse.text15') }}
</template>

<script>
import Logo from '@/assets/img/logo.webp'
import Tablet from '@/assets/img/tablet.webp'
import Girl3 from '@/assets/emoji/girl3.webp'
import Boy2 from '@/assets/emoji/boy2.webp'
import Boy3 from '@/assets/emoji/boy3.webp'

export default {
  name: 'WhyMyCourse',
  data () {
    return {
      Logo,
      Tablet,
      Girl3,
      Boy2,
      Boy3,
      isActive: false
    }
  },
  mounted () {
    setTimeout(() => {this.test = true}, 4000)
  }
}
</script>

<style scoped>

</style>
