<template lang="pug">
  div.d-flex.justify-center.social
    v-container.py-12
      v-row.justify-center.align-center
        v-card
          v-card-text.d-flex.justify-center.flex-wrap
            //a(href="https://www.patreon.com/znikson/posts")
            //  img(:src="Instagram" @click="goTo('https://www.patreon.com/znikson/posts')")
            img(:src="Instagram" @click="goTo('https://www.patreon.com/znikson/posts')")
            img(:src="Tiktok").mx-4
            img(:src="Patreon")
            img(:src="Telegram").mx-4
            img(:src="Youtube")



</template>

<script>
import Logo from '@/assets/img/logo.webp'
import AboutMe from '@/assets/img/about-me.webp'
import Instagram from '@/assets/icons/instagram-dotted.svg'
import Patreon from '@/assets/icons/patreon-dotted.svg'
import Telegram from '@/assets/icons/telegram-dotted.svg'
import Tiktok from '@/assets/icons/tiktok-dotted.svg'
import Youtube from '@/assets/icons/youtube-dotted.svg'

export default {
  name: 'Social',
  data () {
    return {
      Logo,
      AboutMe,
      Instagram,
      Patreon,
      Telegram,
      Tiktok,
      Youtube,
    }
  },
  methods: {
    goTo (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

