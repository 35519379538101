<template lang="pug">
  div
    div.d-flex.justify-center.mb-16
      v-btn(color="primary" @click="isViewForm = !isViewForm") {{ $t('join') }}

    v-dialog(v-model="isViewForm" width="500" )
      v-card
        v-card-title.primary--text {{ $t('enterForm') }}
        v-card-text
          v-text-field(:label="$t('form.name')" :rules="rules" hide-details="auto").mb-4
          v-text-field(:label="$t('email')" :rules="rules" hide-details="auto").mb-4
          //label(for="MazPhoneNumberInput-22_phone_number").input-tel__label {{ $t('form.phone') }}
          VuePhoneNumberInput(
            v-model="form.phone"
            default-country-code="UA"
            :ignored-countries="['RU']"
            color="#ff28a4"
            valid-color="#ff28a4"
            :translations="translations"
          ).mt-8.mb-4
          v-text-field(:label="$t('form.telegram')" :rules="rules" hide-details="auto").mb-4
          v-text-field(:label="$t('form.price')")
          v-text-field(:label="$t('form.comment')")

</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'RegistrationForm',
  components: {
    VuePhoneNumberInput
  },
  data () {
    return {
      isViewForm: false,
      rules: [
        value => !!value || this.$i18n.t('validation.required'),
      ],
      form: {
        phone: null
      },
      translations: {
        countrySelectorLabel: '',
        countrySelectorError: '',
        phoneNumberLabel: this.$i18n.t('form.phone') ,
        phoneNumberLabelError: this.$i18n.t('form.phone') ,
        example: `${ this.$i18n.t('form.phone') },  ${ this.$i18n.t('validation.example') }: `
      }
    }
  }
}
</script>

<style scoped>

</style>
