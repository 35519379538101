<template lang="pug">
  div
    v-container.py-10
      v-row(align="center" justify="center").mb-6
        v-col.col-12.text-center
          div.mb-6.font-50.bold-600 {{ $t('procreate1.text1') }}
            span.font-50.bold-600.primary--text.ml-2 1.0
          div.subtitle.font-18
            span {{ $t('procreate1.text2') }}
            span.primary--text.px-2 {{ $t('procreate1.text3') }}
            span {{ $t('procreate1.text4') }}
          div.subtitle-b.font-18 {{ $t('procreate1.text5') }}

    VueSlickCarousel(v-if="!small" v-bind="settings").pb-16
      img(:src="Work1")
      img(:src="Work2")
      img(:src="Work3")
      img(:src="Work1")

    v-row(v-else align="center" justify="center").pb-10
      div.col-xs-12.col-sm-3.text-center.pa-0
        img(:src="Work1" width="110%" )

      div(
        :class="$vuetify.breakpoint.name === 'sm' && 'ml-5'"
      ).col-xs-12.col-sm-5.text-center.pa-0
        img(:src="Work2" width="92%" )

      div(
        :class="$vuetify.breakpoint.name === 'sm' && 'mr-3'"
      ).col-xs-12.col-sm-3.text-center.pa-0
        img(:src="Work3" width="107%" )

</template>

<script>
import Work1 from '@/assets/works/work1.webp'
import Work2 from '@/assets/works/work2.webp'
import Work3 from '@/assets/works/work3.webp'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Procreate1',
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      Work1,
      Work2,
      Work3,
      settings: {
        centerMode: true,
        // centerPadding: "20px",
        slidesToShow: 3,
        speed: 700,
        arrows: true,
        variableWidth: true
      }
    }
  },
  computed: {
    small () {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    },
  }
}
</script>

<style scoped>

</style>
