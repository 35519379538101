<template lang="pug">
  v-app-bar(
    app
    flat
    height="0"
    absolute
    elevate-on-scroll
  ).mt-12
    v-container
      div.d-flex.align-center.justify-space-between.px-10
        img(:src="Logo" width="140px")
        v-spacer
        div.pointer
          router-link(to="/" :class="lang === 'uk' && 'bold-800'" ).white--text.mr-4 UA
          router-link(to="/ru" :class="lang === 'ru' && 'bold-800'" ).white--text RU

</template>

<script>
import { mapState } from 'vuex'
import Logo from '@/assets/img/logo.webp'

export default {
  name: 'Header',
  data () {
    return {
      Logo
    }
  },
  computed: {
    ...mapState({
      user: state => state.profile.user
    }),
    lang () {
      return this.$route.meta.lang
    }
  },
  methods: {
    out () {
      location = '/login'
    }
  }
}
</script>
