<template lang="pug">
  //div.bg-dot.price
  div.price
    v-container.py-12
      v-row.justify-center.align-center
        div.mb-6.font-50.bold-600 {{ $t('price.title') }}

        v-row( align="center" justify="center").price__list.mb-6
          div.col-xs-12.col-sm-4.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence.pa-0
            v-card(elevation="3" )
              div.d-flex.justify-start
                img(:src="Green" width="250px")
              v-card-title.d-flex.flex-column
                div.title.primary--text.mb-6 {{ $t('price.text1') }}
                div.bold-800.font-50.mb-4 50$
                div.text-back.success--text 50$
                div ≈ 2000 UAH
              v-card-text
                ul.px-8.text-left
                  li.subtitle-b.mb-1.white--text {{ $t('price.text3') }}
                  li.subtitle-b.mb-1.white--text {{ $t('price.text4') }}
                  li.subtitle.mb-1.grey--text {{ $t('price.text5') }}
                  li.subtitle.mb-1.grey--text {{ $t('price.text6') }}
                  li.subtitle.mb-1.grey--text {{ $t('price.text7') }}
                  li.subtitle.grey--text {{ $t('price.text8') }}

          div.col-xs-12.col-sm-4.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence.pa-0
            v-card(elevation="3" light)
              div.d-flex.justify-start
                img(:src="Orange" width="250px")
              v-card-title.d-flex.flex-column
                div.title.primary--text.mb-6 {{ $t('price.text2') }}
                div.bold-800.font-50.mb-4 100$
                div.text-back.warning--text 100$
                div ≈ 4000 UAH
              v-card-text
                ul.px-8.text-left
                  li.subtitle-b.mb-1.black--text {{ $t('price.text3') }}
                  li.subtitle-b.mb-1.black--text {{ $t('price.text4') }}
                  li.subtitle-b.mb-1.black--text {{ $t('price.text9') }}
                  li.subtitle-b.mb-1.black--text {{ $t('price.text10') }}
                  li.subtitle.mb-1.grey--text {{ $t('price.text7') }}
                  li.subtitle.grey--text {{ $t('price.text8') }}

          div.col-xs-12.col-sm-4.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence.pa-0
            v-card(elevation="3").shadow
              div.d-flex.justify-start
                img(:src="Primary" width="250px")
              v-card-title.d-flex.flex-column
                div.title.primary--text.mb-6 {{ $t('price.text13') }}
                div.bold-800.font-50.mb-4 150$
                div.text-back.primary--text 150$
                div ≈ 6000 UAH
              v-card-text
                ul.px-8.text-left
                  li.subtitle-b.mb-1.white--text {{ $t('price.text3') }}
                  li.subtitle-b.mb-1.white--text {{ $t('price.text4') }}
                  li.subtitle-b.mb-1.white--text {{ $t('price.text9') }}
                  li.subtitle-b.mb-1.white--text {{ $t('price.text10') }}
                  li.subtitle-b.mb-1.primary--text {{ $t('price.text11') }}
                  li.subtitle-b.primary--text {{ $t('price.text12') }}


        //v-row.justify-center.align-center.mt-8
        //  v-col.col-7
        //    div.sumtitle.mb-2 {{ $t('united.text1') }}
        //    div.subtitle {{ $t('united.text2') }}
        //    div.subtitle-b.mb-2 {{ $t('united.text3') }}
        //    div.subtitle {{ $t('united.text4') }}
        //
        //  v-col.col-5
        //    img(:src="United24" width="350px").mt-4

</template>

<script>
import United24 from '@/assets/img/united24.webp'
import Red from '@/assets/img/card-danger.webp'
import Green from '@/assets/img/card-success.webp'
import Orange from '@/assets/img/card-warning.webp'
import Primary from '@/assets/img/card-primary.webp'

export default {
  name: 'Price',
  data () {
    return {
      United24,
      Primary,
      Green,
      Orange,
      Red,
      isActive: false
    }
  },
}
</script>

<style scoped>

</style>
