<template lang="pug">
  div.about-procreate
    v-parallax(
      dark
      height="fit-content"
      :src="Background"
    )
      v-container.pt-16
        v-lazy(
          v-model="isActiveLogo"
          :options="{ threshold: 0.7 }"
          min-height="200"
          transition="fade-transition"
        )
          v-row(v-if="isActiveLogo" align="center" justify="center").mb-6
            img(:src="ProcreateLogo" width="450px").about-procreate__logo.mt-10

        v-lazy(
          v-model="isActivePart1"
          :options="{ threshold: 0.7 }"
          min-height="200"
          transition="fade-transition"
        )
          v-row(v-if="isActivePart1" align="center" justify="center").mb-6
            div.col-xs-6.col-sm-6.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
              span
                img(:src="Paint" width="100px")
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text1.part1') }}
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text1.part2') }}

            div.col-xs-6.col-sm-6.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
              span
                img(:src="Wall" width="100px")
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text2.part1') }}
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text2.part2') }}

            div.col-xs-6.col-sm-6.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
              span
                img(:src="Cinema" width="100px")
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text3.part1') }}
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text3.part2') }}

            div.col-xs-6.col-sm-6.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
              span
                img(:src="Brash" width="100px")
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text4.part1') }}
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text4.part2') }}

            div.col-xs-6.col-sm-6.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
              span
                img(:src="Sculpture" width="100px")
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text5.part1') }}
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text5.part2') }}

            div.col-xs-6.col-sm-6.col-md-4.col-lg-4.text-center.cssanimation.leFadeInLeft.sequence
              span
                img(:src="Book" width="100px")
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text6.part1') }}
                div.subtitle.overline.line-h20 {{ $t('aboutProcreate.text6.part2') }}


</template>

<script>
// import Background from '@/assets/img/waves-white.svg'
import Background from '@/assets/img/back-3.jpg'
import ProcreateLogo from '@/assets/img/procreate-logo.webp'

import Paint from '@/assets/icons/paint.webp'
import Wall from '@/assets/icons/wall.webp'
import Cinema from '@/assets/icons/cinema.webp'
import Brash from '@/assets/icons/brash.webp'
import Sculpture from '@/assets/icons/sculpture.webp'
import Book from '@/assets/icons/book.webp'

export default {
  name: 'AboutProcreate',
  data () {
    return {
      Background,
      ProcreateLogo,
      Paint,
      Wall,
      Cinema,
      Brash,
      Sculpture,
      Book,
      isActiveLogo: false,
      isActivePart1: false,
      isActivePart2: false,
    }
  }
}
</script>

<style scoped>

</style>
