import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/plugins/vue-i18n'

Vue.use(Router)

export const routes = [
  {
    path: '*',
    name: 'all',
    redirect: '/404'
  },
  {
    path: '/',
    name: 'main',
    meta: {
      lang: 'uk'
    },
    component: () => import('@/views/Main')
  },
  {
    path: '/ru',
    name: 'main-ru',
    meta: {
      lang: 'ru'
    },
    component: () => import('@/views/Main')
  },
  {
    path: '/404',
    name: 'no-found',
    component: () => import('@/views/NotFound')
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  i18n.locale = to.meta.lang
  next()
})

export default router
