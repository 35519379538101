<template lang="pug">
  v-app(
    theme="dark"

  )
    //v-container
    //Header
    Main
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Header from '@/components/Header'
import Main from '@/views/Main'

export default {
  name: 'App',
  components: {
    Header,
    Main,
  },
  data () {
    return {
      drawer: null,
      isContentBoxed: false,
    }
  },
  computed: {
    ...mapState({
      isLoadApp: state => state.app.isLoad
    }),
  },
  mounted () {
    // this.isAuth && this.getDirectories()
  },
  methods: {
    ...mapActions([''])
  }
};
</script>
