<template lang="pug">
  div.feedback
    v-parallax(
      dark
      height="fit-content"
      :src="Background"
    )
      v-container
        v-row(align="center" justify="center").mb-6
          div.col-xs-12.col-sm-12.col-md-4.col-lg-4.text-center.cssanimation.leScaleYIn.sequence
            span
              img(:src="Girl1" width="120px").mb-2
              div.title.mb-4 Настя
              div "Даже человеку, впервые взявшему планшет на первом уроке, становится понятно, что для чего предназначено и как этим пользоваться, чтобы сделать красоту."

          div.col-xs-12.col-sm-12.col-md-4.col-lg-4.text-center.cssanimation.leScaleYIn.sequence
            span
              img(:src="Girl2" width="100px")
              div.title.mb-4 Юля
              div "Конструктивно! Весь прокриейт теперь как на ладони! Полный разбор программы с бонусом разборок домашних заданий. Советую!"

          div.col-xs-12.col-sm-12.col-md-4.col-lg-4.text-center.cssanimation.leScaleYIn.sequence
            span
              img(:src="Boy1" width="110px").mb-2
              div.title.mb-4 Дима
              div "Мне очень понравилось, что были примеры, для меня это самая удобная форма усвоения информации. Доступом к записям уроков пользуюсь до сих пор. Рекомендую!"

</template>

<script>
import Background from '@/assets/img/back-2.jpg'
// import Background from '@/assets/curved/curved11.jpg'

import Girl1 from '@/assets/emoji/girl1.webp'
import Girl2 from '@/assets/emoji/girl2.webp'
import Boy1 from '@/assets/emoji/boy1.webp'

export default {
  name: 'Feedback',
  data () {
    return {
      Background,
      Girl1,
      Girl2,
      Boy1,
      isActiveLogo: false,
      isActivePart1: false,
      isActivePart2: false,
    }
  }
}
</script>

<style scoped>

</style>
