import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router'

import i18n from '@/plugins/vue-i18n'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import moment from 'moment'

Vue.use(Vuelidate)
Vue.use(VueTheMask)

import '@/assets/sass/index.sass'

Vue.config.productionTip = false

Vue.prototype.$moment = moment

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
