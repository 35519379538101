<template lang="pug">
  div
    v-parallax(
      dark
      height="700"
      :src="Background"
    )
      Header
      v-row(align="center" justify="center")
        v-col.col-12.text-center
          div.title-big.mb-6 {{ $t('title.text1') }}
          div.subtitle-b400.font-20 {{ $t('title.text2') }}
          div.subtitle-b400.font-20 {{ $t('title.text3') }}
          div.subtitle-b400.font-20 {{ $t('title.text4') }}

      RegistrationForm

</template>

<script>
import Header from '@/components/Header'
import Background from '@/assets/img/back-1.webp'
import RegistrationForm from '@/components/RegistrationForm'
import Logo from '@/assets/img/logo.webp'


export default {
  name: 'Title',
  components: {
    Header,
    RegistrationForm,
  },
  data () {
    return {
      Background,
      Logo,
    }
  }
}
</script>

<style scoped>

</style>
