<template lang="pug">
  div.d-flex.about-me.pb-10
    v-container.py-12
      v-row.justify-center.align-center
        v-col.col-lg-5.col-md-5.col-sm-12.mr-4
          div.cssanimation.leFlyInLeft.sequence
            span
              div.font-40.bold-600.mb-6 {{ $t('hello') }}
              div.subtitle.font-18 {{ $t('aboutME.text1') }}
              div.subtitle.font-18 {{ $t('aboutME.text2') }}
              div.subtitle.font-18.mb-6 {{ $t('aboutME.text3') }}
                span.subtitle-b.primary--text.font-18.ml-2 {{ $t('aboutME.text4') }}

              div.subtitle.font-18 {{ $t('aboutME.text5') }}
              div.subtitle.font-18 {{ $t('aboutME.text6') }}
              div.subtitle.font-18.bold-800 {{ $t('aboutME.text7') }}
              img(:src="Logo" width="120px").mt-4

        v-col.col-lg-6.col-md-5.col-sm-12
          div.cssanimation.leFlyInRight.sequence
            span.parts.ml-12
              img(:src="AboutMe" width="350px")
              img(:src="Part1" width="330px").filter-primary.cssanimation.pepe
              img(:src="Part2" width="330px").cssanimation.hu__hu__
              img(:src="Part3" width="330px").filter-white.cssanimation.heartbeatFast.infinite
              img(:src="Part4" width="330px").filter-white.cssanimation.coolVerticalShake.infinite
              img(:src="Part5" width="330px").filter-white.cssanimation.pulseShake.infinite
              img(:src="Part6" width="330px").filter-white.cssanimation.vibration.infinite
              img(:src="Part7" width="330px").filter-white.cssanimation.pullRelease.infinite
              img(:src="Part8" width="330px").filter-white.cssanimation.blurIn.infinite

</template>

<script>
import Logo from '@/assets/img/logo.webp'
import AboutMe from '@/assets/aboutMe/main.png'
import Part1 from '@/assets/aboutMe/part1.png'
import Part2 from '@/assets/aboutMe/part2.png'
import Part3 from '@/assets/aboutMe/part3.png'
import Part4 from '@/assets/aboutMe/part4.png'
import Part5 from '@/assets/aboutMe/part5.png'
import Part6 from '@/assets/aboutMe/part6.png'
import Part7 from '@/assets/aboutMe/part7.png'
import Part8 from '@/assets/aboutMe/part8.png'

export default {
  name: 'AboutMe',
  data () {
    return {
      Logo,
      AboutMe,
      Part1,
      Part2,
      Part3,
      Part4,
      Part5,
      Part6,
      Part7,
      Part8,
    }
  }
}
</script>
