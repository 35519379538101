<template lang="pug">
  div
    v-parallax(
      dark
      height="fit-content"
      :src="Background"
    )
      v-container
        v-row(align="center" justify="center")
          div.mb-6.mt-6.font-50.bold-600 {{ $t('program.text1') }}

        v-timeline(:dense="small")
          v-timeline-item(left)
            template(v-slot:icon)
              img(:src="Picture" width="130")
            div(
              :class="small ? 'pl-10' : 'pr-10'"
              ).cssanimation.leFlyInLeft.sequence
              span
                div(
                  :class="small ? 'text-left mb-2' : 'text-right'"
                ).subtitle-b.font-20 {{ $t('program.text2') }}
                div.subtitle.text-justify {{ $t('program.text3') }}

          v-timeline-item(right)
            template(v-slot:icon)
              img(:src="Book" width="130")
            div.cssanimation.leFlyInRight.sequence.pl-10
              span
                div.subtitle-b.mb-2.font-20.text-left {{ $t('program.text4') }}
                div.subtitle.text-justify {{ $t('program.text5') }}

          v-timeline-item(left)
            template(v-slot:icon)
              img(:src="Teacher" width="130")
            div(
              :class="small ? 'pl-10' : 'pr-10'"
              ).cssanimation.leFlyInLeft.sequence
              span
                div(
                  :class="small ? 'text-left mb-2' : 'text-right'"
                ).subtitle-b.font-20 {{ $t('program.text6') }}
                div.subtitle.text-justify {{ $t('program.text7') }}

          v-timeline-item(right)
            template(v-slot:icon)
              img(:src="Painter" width="130")
            div.cssanimation.leFlyInRight.sequence.pl-10
              span
                div.subtitle-b.mb-2.font-20.text-left {{ $t('program.text8') }}
                span.subtitle.mr-2 {{ $t('program.text9') }}
                span.subtitle-b.primary--text.mr-2 {{ $t('program.text10') }}
                span.subtitle.mr-2 {{ $t('program.text11') }}
                span.subtitle-b.primary--text {{ $t('program.text12') }}

</template>

<script>
import Background from '@/assets/img/back-5.jpg'
import Picture from '@/assets/icons/picture.webp'
import Book from '@/assets/icons/book.webp'
import Teacher from '@/assets/icons/teacher.webp'
import Painter from '@/assets/icons/painter.webp'


export default {
  name: 'Program',
  data () {
    return {
      Background,
      Picture,
      Book,
      Teacher,
      Painter,
      isActiveTitle: false,
      isActivePart1: false,
      isActivePart2: false,
    }
  },
  computed: {
    small () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  }
}
</script>

<style scoped>

</style>
