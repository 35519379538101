<template lang="pug">
  div.bg-lines
    v-container
      div.mb-6.font-50.bold-600
        span.mr-4 {{ $t('faq.title.part1') }}
        span.primary--text {{ $t('faq.title.part2') }}

      div(v-for="q in questionsCount").mb-4
        div.subtitle-b.primary--text {{ $t(`faq.question${q}`) }}
        div.subtitle {{ $t(`faq.answer${q}`) }}


</template>

<script>
import Background from '@/assets/img/waves-white.svg'

export default {
  name: 'FAQ',
  data () {
    return {
      Background,
      questionsCount: 9
    }
  }
}
</script>

<style scoped>

</style>
