<template lang="pug">
  v-main.bg-dot
    Title
    v-lazy(
      v-model="isActiveAboutMe"
      :options="{ threshold: 0.6 }"
      min-height="200"
      transition="fade-transition"
    )
      AboutMe(v-if="isActiveAboutMe" )
    Social
    AboutProcreate
    Procreate1
    v-lazy(
      v-model="isActiveFeedback"
      :options="{ threshold: 0.6 }"
      min-height="200"
      transition="fade-transition"
    )
      Feedback(v-if="isActiveFeedback" )
    v-lazy(
      v-model="isActiveWhyMyCourse"
      :options="{ threshold: 0.6 }"
      min-height="200"
      transition="fade-transition"
    )
      WhyMyCourse(v-if="isActiveWhyMyCourse" )
    v-lazy(
      v-model="isActiveProgram"
      :options="{ threshold: 0.7 }"
      min-height="200"
      transition="fade-transition"
    )
      Program(v-if="isActiveProgram" )
    Price
    FAQ

</template>

<script>
import Title from '@/views/Title'
import AboutMe from '@/views/AboutMe'
import Social from '@/views/Social'
import AboutProcreate from '@/views/AboutProcreate'
import Procreate1 from '@/views/Procreate1'
import Feedback from '@/views/Feedback'
import WhyMyCourse from '@/views/WhyMyCourse'
import Program from '@/views/Program'
import Price from '@/views/Price'
import FAQ from '@/views/FAQ'

export default {
  name: 'Main',
  components: {
    Title,
    AboutMe,
    Social,
    AboutProcreate,
    Procreate1,
    Feedback,
    WhyMyCourse,
    Program,
    Price,
    FAQ,
  },
  data () {
    return {
      isActiveAboutMe: false,
      isActiveFeedback: false,
      isActiveWhyMyCourse: false,
      isActiveProgram: false,
    }
  }
}
</script>
