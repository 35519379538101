export default {
  hello: '👋 Привіт!',
  title: {
    text1: 'Procreate 2.0 Course',
    text2: 'Вивчи програму від А до Я,',
    text3: 'отримай реальну практику та фідбек.',
    text4: 'почни нарешті малювати без обмежень!',
  },
  aboutME: {
    text1: 'Мене звати Микита Зінченко,',
    text2: 'я блогер-ілюстратор, і я малюю',
    text3: 'в Procreate',
    text4: 'вже більше 5 років.',
    text5: '90% усіх моїх проектів я зробив',
    text6: 'в цій програмі, а малюнки в ній принесли мені більше',
    text7: '2 млн. підписників!',
  },
  aboutProcreate: {
    text1: {
      part1: 'Різні стилі',
      part2: 'digital-малюнку',
    },
    text2: {
      part1: 'Робота з ефектами',
      part2: 'та обробка',
    },
    text3: {
      part1: 'Покадрова',
      part2: 'анімація',
    },
    text4: {
      part1: 'Студія зі',
      part2: 'створення пензлів',
    },
    text5: {
      part1: 'Текстурування й',
      part2: 'покраска 3D моделей',
    },
    text6: {
      part1: 'Скетчинг, нотатки й',
      part2: 'робота з документами',
    },
  },
  procreate1: {
    text1: 'Procreate',
    text2: 'В першому потоці було',
    text3: '50 ученів',
    text4: '😱 та ось деякі роботи з курса',
    text5: '(2/3 намальовані зовсім початківцями)',
  },
  whyMyCourse: {
    text1: 'Чому саме мій курс?',
    text2: {
      part1: 'Навчання від практикуючого',
      part2: 'професійного ілюстратора 😎',
    },
    text3: {
      part1: 'Усі функції Procreate від А до Я',
      part2: '(ідеально як для початківця, так і для профі) ✅',
    },
    text4: {
      part1: 'Теорія й практичні завдания',
      part2: 'для 100% результата 🚀',
    },
    text5: {
      part1: 'Разбір ваших робіт',
      part2: 'після кожного д/з',
    },
    text6: 'Для кого цей курс? 🤔',
    text7: {
      part1: 'Починаючий',
      part2: 'digital художник',
    },
    text8: 'Я не знаю з чого почати. Я трохи малюю, але не виходить крутий результат.',
    text9: 'Мені потрібна практика, теоритичні та технічні знання, щоб вийти на новий рівень!',
    text10: {
      part1: 'Хочу серйозно',
      part2: 'почати малювати',
    },
    text11: 'В мене лежить iPad та навіть встановлений Procreate, але я не знаю, що з тим робити.',
    text12: 'Я хочу навчитися малювати та більш серйозно цим займатись, а після почати заробояти.',
    text13: {
      part1: 'Саморозвиток та',
      part2: '"для себе"',
    },
    text14: 'Мені інколи требі щось намалювати для роботи або для себе, та я не дуже вмію.',
    text15: 'Ще буває так, що щось в голові можу уявити, а ось зобразити не виходить.('
  },
  program: {
    text1: 'Програма 🔥',
    text2: '6 уроків',
    text3: 'Програма складається з 6 занять – це більше 8-ми часів теорії, де я разкажу про УСІ функції та секрети ' +
      'програми, кожну кнопку й налаштування.',
    text4: 'Домашка',
    text5: 'Після кожного занятя є тематичне практичне завдання. Воно прокачає як знання програми,' +
      ' так й вміння малювати.',
    text6: 'Розбори',
    text7: 'Я розбираю ваші домашні завдання на окремій онлайн зустрічі та ділюсь порадами й думками щодо покращення ' +
      'ваших робіт.',
    text8: 'Для пакету "Профі"',
    text9: 'Я також',
    text10: 'проведу додатковий мастермайнд',
    text11: 'з гайдом та порадами, как заробляти починаючому ілюстратору у 2022 й як безкоштовно просуватися в мережі.',
    text12: '+ Окрема сесія питання-відповідь по функціям програми.',
  },
  price: {
    title: 'Пакети 🛍',
    text1: 'ВСЕ САМ 🤓',
    text2: 'ХУДОЖНИК 😎',
    text3: '6 відео уроків',
    text4: 'Домашні завдания',
    text5: 'Немає разборів д/з',
    text6: 'Немає доступу до відео після курсу',
    text7: 'Немає дод. сесії питання-відповідь',
    text8: 'Немає бонусного мастермайнда',
    text9: '6 разборів д/з',
    text10: 'Безмежний доступ до матеріалів',
    text11: 'Дод. сесія питання-відповідь',
    text12: 'Бонусний мастермайнд',
    text13: 'ПРОФІ 😍'
  },
  united: {
    text1: '⚠️ ️А поки я проводжу позитивний курс та вчу вас заробляти й створювати прекрасне, ' +
      'в Україні продовжується жахлива війна, розв\'язана РФ.',
    text2: 'Тому 10% від зароблених грошей з курсу буде відправлено у фонд відновлення України UNITED 24',
    text3: '(звіт буде висланий усім учням)',
    text4: 'Слава Україні! 🇺🇦❤️'
  },
  faq: {
    title: {
      part1: 'Часті',
      part2: 'питання 😅',
    },
    question1: 'Коли почнеться курс?',
    answer1: 'Курс почнеться після набору групи, але не раніше 20 вересня та не пізніше 5 жовтня. ' +
      'Все залежить від кількості бажаючих. Якщо курс не відбудеться по який-небудь причині (війна в країні так-то), ' +
      'то усі гроші будуть повернені на карти в повному обсязі.',
    question2: 'Якою мовою будуть заняття?',
    answer2: 'Матервали відео будуть на російській мові. На минулому потоуі було багато учнів з Прибалтики, Німеччини, ' +
      'Швейцарії, Польші, Ізраілю та інших країн. Вони поки не вивчили українську, тому поки що так',
    question3: 'Чи доступний курс для жителів РФ?',
    answer3: 'Ні. Імпортозамінюйте якось.',
    question4: 'На якій платформі курс?',
    answer4: 'Відео будуть завантажуватись в мережу з обмеженим доступом та їх можна буде просто подивитись ' +
      'за посиланням без реєстрації та СМС. А спілкування групи, нагадування про заняття, сбір домашек та все-все-все ' +
      'інше в телеграм каналі й відповідному чатику. ',
    question5: 'Как відбувається оплата?',
    answer5: 'На карту або через PAYPAL. Для фанатів можна й в крипті (USDT, BUSD)',
    question6: 'А що, якщо я зовсім не вмію малювати, ну зовсім?\n',
    answer6: 'На першому потоці були такі учні й вони добилися дуже крутих результатів. Раджу брать другий чи ' +
      'третій пакет з розбором домашек та виконувати все на 100%. Я допоможу та підтримую тебе й нарнатую 100% результат. ' +
      'Я через ZOOM вчу 8-річного хлопчика з Швейцарії, тому з тобою ми точно зможемо не гірше.)',
    question7: 'А ось якщо я вже вмію малювати та знаю Procreate?',
    answer7: 'Тоді все рівно раджу пройти курс, так як ми проходимр усі функції, а 99% користувачів використовують ' +
      'далеко не все, що може ця програма й не розкривають її та свій потенціал. Тому ти точно дізнаєшся ' +
      'щось нове, а всі практичні завдания зроблені так, що вони стануть для тебя випробування, що прокачає ' +
      'твій рівень та додасть робіт в твоє портфоліо. ',
    question8: 'Що потрібно, щоб пройти курс?',
    answer8: 'Потрібен будь-який iPad та програма Procreate. Одразу відповідь на наступне питання: якщо немає програми, ' +
      'якщо інший планшет і тому подібне - курс не для тебе. ',
    question9: 'Чи є знижки?',
    answer9: 'Знижок нема. Я збирав ці знання не один рік та спеціально поставив ціни у багато разів нижче реальних, ' +
      'щоб якомога більша кількість людей мала можливість прокачати себе або навіть почати заробляти. ' +
      'Так що знижки вже враховані.)',
  },
  registration: 'Реєстрація',
  join: 'Приєднатися',
  enterForm: 'Заповни форму',
  form: {
    name: 'Імʼя',
    phone: 'Номер телефону',
    telegram: 'Телеграм',
    price: 'Пакет',
    comment: 'Коментар'
  },
  email: 'Email',
  validation: {
    required: 'Поле обовʼязкове',
    phoneLength: 'Номер не дійсний',
    example: 'приклад',
  }
}
